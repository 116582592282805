import { Application, Controller } from "stimulus";

const cardViewTimeInterval = 100;

export default class ViewTourController extends Controller {
  static targets = ["card"];
  static values = {
    placeIdTour: String,
    idTour: String,
    tourType: String,
    cardContext: String,
    trackViews: { type: Boolean, default: false }
  };

  connect() {

    const affIneligibleCookie = this.readCookie('aff_ineligible');

    if (this.trackViewsValue && affIneligibleCookie === undefined) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          this.trackTourCardViews(entry);  // arrow function preserves `this` context
        });
      }, { threshold: [0.5, 0.5] });

      this.observer = observer;  // store observer as instance property
      observer.observe(this.element);
    }
  }

  readCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  trackTourCardViews(entry) {

    let id = entry.target.getAttribute("data-view-tour-id-value");
    let reference = entry.target.getAttribute("data-view-reference-value");
    let position = entry.target.getAttribute("data-view-position-value");
    let filters = entry.target.getAttribute("data-view-filters-value");

    if (this.tourIsPreviouslyViewed(id)) {
      this.observer.unobserve(entry.target);  // use `this.observer`
      return;
    }

    if (entry.isIntersecting) {
      if (entry.intersectionRatio >= 0.5) {
        entry.target.dataset.lastViewedTime = entry.time;
        var intervalId = window.setInterval((card, tourId, reference, position, filters) => {
          let currentTime = performance.now();
          let cardLastViewedTime = card.dataset.lastViewedTime;

          if (cardLastViewedTime > 0 && currentTime - cardLastViewedTime > cardViewTimeInterval) {
            setTimeout(() => {
              fetch("/events/tour_card_viewed", {
                method: "POST",
                mode: "cors",
                credentials: "same-origin",
                headers: { "Content-Type": "application/json" },
                referrerPolicy: "no-referrer",
                body: JSON.stringify({ tour_id: tourId, position, reference, filters })
              });

              fetch("/tracking/tour_card_views", {
                method: "POST",
                mode: "cors",
                credentials: "same-origin",
                headers: { "Content-Type": "application/json" },
                referrerPolicy: "no-referrer",
                body: JSON.stringify({ tour_id: tourId, position, reference, filters })
              });
            }, 0);

            this.addIdToTourToursViewedCookie(tourId);
            clearInterval(intervalId);
            this.observer.unobserve(card);
          } else {
            clearInterval(intervalId);
          }
        }, cardViewTimeInterval, entry.target, id, reference, position, filters);
      } else {
        entry.target.dataset.lastViewedTime = 0;
      }
    } else {
      entry.target.dataset.lastViewedTime = 0;
    }
  }

  tourIsPreviouslyViewed(id) {
    var toursViewed = sessionStorage.getItem("tours_viewed")
    let isViewed = false
    if (toursViewed) {
      var tourIds = sessionStorage.getItem("tours_viewed").split("|")
      isViewed = tourIds.includes(id)
    }
    return isViewed
  }

  addIdToTourToursViewedCookie(id) {
    let currentValue = sessionStorage.getItem("tours_viewed")

    if (currentValue) {
      currentValue = currentValue.concat("|", id);
    } else {
      currentValue = "|".concat(id);
    }
    currentValue = sessionStorage.setItem("tours_viewed", currentValue)
  }

  trackTourClick(e) {
    this.trackTourClickGtag(e);
    this.publishTourCardClickEvent();
  }

  async trackTourClickFromList() {
    const cardsByContext = document.querySelectorAll(`[data-view-tour-card-context-value='${this.cardContextValue}']`);
    const cardIndex = [...cardsByContext].findIndex(card => String(card.dataset.viewTourIdTourValue) === String(this.idTourValue));

    const dataset = {
      tourId: this.idTourValue,
      tourType: this.tourTypeValue,
      placeId: this.placeIdTourValue,
      cardContext: this.cardContextValue,
      ...(cardIndex !== -1 && { viewPositionValue: String(cardIndex) } ),
    }

    this.trackTourClickRudderstack(dataset);
  }

  async trackTourClickGtag(e) {
    const tour_id = this.cardTarget.dataset.viewTourIdValue;
    const filters = this.cardTarget.dataset.viewFiltersValue;
    const reference = this.cardTarget.dataset.viewReferenceValue;
    const position = this.cardTarget.dataset.viewPositionValue;
    const locale = this.cardTarget.dataset.viewLocaleValue;

    const track_params = {
      tour_id: parseInt(tour_id),
      position: parseInt(position),
      reference: reference,
      filters: JSON.parse(filters)
    };

    var track_date = {};
    if (e.target.closest('[data-controller=fake-link]')) {
      track_date = { date: e.target.closest('[data-controller=fake-link]').dataset.date }
    }

    fetch("/tracking/tour_clicks", {
      method: 'post',
      body: JSON.stringify({ ...track_params, ...track_date }),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin'
    });

    const tourContent = document.querySelector(`[data-tour-id='${tour_id}']`);
    var event_data = {
      item_list_name: "Place",
      item_list_id: tourContent.dataset.placeName,
      items: [
        {
          id: tour_id,
          name: tourContent.querySelector(".place-tour-title").innerText,
          brand: tourContent.dataset.guru,
          category: tourContent.dataset.placeName,
          variant: locale,
          price: tourContent.dataset.price
        }
      ]
    }
    gtag("event", "select_item", event_data);
  }

  publishTourCardClickEvent() {
    const dataset = this.cardTarget?.dataset;
    if (!dataset) return;

    this.trackTourClickRudderstack(dataset);
  }

  trackTourClickRudderstack(dataset) {
    window.rudderanalytics.track("Tour Card Clicked", {
      city_id: dataset.placeId,
      tour_id: dataset.tourId,
      tour_type: dataset.tourType,
      tour_score: dataset.tourScore,
      tour_quality: dataset.tourQuality,
      card_context: dataset.cardContext,
      card_position: dataset.viewPositionValue
    });
  }
}

const application = Application.start()
application.register("view-tour", ViewTourController)
